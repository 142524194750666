import React, { ReactNode, ButtonHTMLAttributes, useRef } from 'react';
import cx from 'classnames';
import styles from './Button.module.css';
import { ButtonArrow } from './ButtonArrow';
import { useButtonArrowResizer } from './useButtonArrowResizer';

interface ButtonProperties extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'children'> {
  children?: ReactNode;
  reverse?: boolean;
  theme?: 'outline' | 'outlineInverted' | 'square';
}

export const VanErkButton = ({
  children,
  disabled,
  reverse = false,
  theme,
  type = 'button',
  ...rest
}: ButtonProperties) => {
  const innerReference = useRef<HTMLAnchorElement>(null);
  const svgReference = useRef<SVGSVGElement>(null);

  useButtonArrowResizer(innerReference, svgReference);

  return (
    <button
      disabled={disabled}
      className={getClassNames(theme, reverse)}
      type={type === 'submit' ? 'submit' : type === 'reset' ? 'reset' : 'button'}
      {...rest}>
      <span ref={innerReference} className={styles.inner}>
        {children}
      </span>
      {theme === 'square' ? undefined : <ButtonArrow ref={svgReference} />}
    </button>
  );
};

const getClassNames = (theme: ButtonProperties['theme'], reverse: boolean) =>
  cx(styles.sharedStyles, styles.vanErk, {
    [styles.outline]: theme === 'outline',
    [styles.outlineInverted]: theme === 'outlineInverted',
    [styles.reverse]: reverse,
    [styles.square]: theme === 'square',
  });
